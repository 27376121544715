<template>
  <div>
    <van-cell>
      <template slot="title">
        <span style="color: #57c4b7"
          >*温馨提示：请选择成员来进行核酸自助开单记录查询</span
        >
      </template>
    </van-cell>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell-group>
        <van-cell
          v-for="item in list"
          :key="item + 'FCardL'"
          :title="item.userName"
          :value="item.userID"
          @click="
            onclickselect(
              item.userID,
              item.userName,
              item.userID,
              item.cardNo,
              item.idCard,
              item.phone
            )
          "
        ></van-cell>
      </van-cell-group>
    </van-list>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant"; //弹出框
import ajax from "../../lib/ajax";
import store from "../../store";
// var lists;
export default {
  data() {
    return {
      list: [],
      // list: [{
      //   PatientName: "李子",
      //   CardNumber: "123456467",
      //   Idcard:"492527199505142222",
      // },
      // {
      //   PatientName: "王往",
      //   CardNumber: "5445645646",
      //   Idcard:"532527199505142222",
      // }],
      loading: false,
      finished: false,
    };
  },

  methods: {
    async onLoad() {
      var wxopenid = store.getters["wechat/openid"];
      await ajax
        .get("/Api/CardService/WeChatGetMembers?input=" + wxopenid)
        .then((res) => {
          const lists = res.data.result;
          this.list = lists;
          console.log(lists);
        })
        .catch((err) => {
          console.log(err.data);
          Toast("获取失败，请稍后重试！");
        });
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.list.push(this.list.length);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length == this.list.length) {
          this.finished = true;
        }
      }, 500);
    },
    async onclickselect(userId, userName, patientId, cardNo, idCard, phone) {
      store.commit("store/setUserId", userId);
      // alert(Idcard);
      store.commit("store/setUserName", userName);
      store.commit("store/setPatientid", patientId);
      store.commit("store/setCardNo", cardNo);
      store.commit("store/setIdcard", idCard);
      store.commit("store/setPhone", phone);
      // this.$store.dispatch('setsyncu',userName);
      this.$router
        .replace({
          //path:'/DepS/:Idcard',
          path: "/qCorsFL",
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeRouteLeave(to, from, next){
    console.log("我是卫士")
    if(to.path === "/CWait"){
      this.$router.push({path:"/Query"});
    }else{
      next();
    }
  },
};
</script>

<style>
</style>
